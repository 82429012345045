/* General styling for the product details page */
.productDetailsContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.productImageLarge {
  width: 50%;
  max-width: 600px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.productDetailsInfo {
  flex: 1;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.shoppingCard {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: calc(25% - 20px);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
}

.productImage {
  width: 100%;
  height: auto;
}

.productInfo {
  text-align: center;
}

.productTitle {
  font-size: 1.2em;
  margin: 10px 0;
}

.productDescription {
  font-size: 0.9em;
  color: #666;
}

.productPrice {
  font-size: 1.1em;
  color: #333;
  margin: 10px 0;
}

.addToCartBtn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.addToCartBtn:hover {
  background-color: #218838;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .productDetailsContainer {
    flex-direction: column;
    align-items: center;
  }

  .productImageLarge,
  .productDetailsInfo {
    width: calc(100% - 40px); /* Full width with some padding */
    max-width: none;
    margin: 0 auto;
  }

  .productImageLarge {
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .productDetailsInfo {
    text-align: center;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .shoppingCard {
    width: calc(100% - 40px); /* Full width with some padding */
  }

  .productsContainer {
    flex-direction: column;
    align-items: center;
  }
}
