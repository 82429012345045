.frameContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .frameRow {
    display: flex;
    flex: 1;
  }
  
  .frame {
    flex: 1;
    border: 2px solid #007BFF; /* Blue border */
    margin: 5px;
    text-align: center;
  }
  
  .frame:hover {
    background-color: #E7F3FF; /* Light blue background on hover */
  }
  
  