/* src/pages/webelements/LoginBasicAuthResultPage.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .logoutButton {
    width: 320px;
    padding: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logoutButton:hover {
    background-color: #e53935;
  }
  