.header {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.site-icon {
  width: 50px;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.nav-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-list.open {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #333;
  z-index: 1000;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list li a, .nav-list li button {
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
}

.nav-list li button {
  padding: 0;
}

.nav-list li a:hover, .nav-list li button:hover {
  text-decoration: underline;
}

.cart-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .nav-list {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .nav-left {
    justify-content: space-between;
  }

  .nav-list.open {
    display: flex;
  }

  .nav-list li {
    margin: 10px 0;
    text-align: center;
  }
}
