.scroll-container {
    height: 100vh; /* Full viewport height */
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .content {
    height: 200vh; /* Make the content area twice as tall as the viewport to ensure scrolling */
  }
  
  .scroll-button {
    display: block;
    margin: 50px auto; /* Center the button horizontally */
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .scroll-button:hover {
    background-color: #45a049;
  }
  