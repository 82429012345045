/* General styling for the shopping page */
.content {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Filter container styling */
  .filter-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 20px;
  }
  
  .search-box {
    padding: 10px;
    font-size: 1em;
  }
  
  .slider-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .price-range {
    margin-top: 10px;
    font-size: 1em;
  }
  
  .horizontal-slider {
    width: 100%;
    margin-top: 10px;
  }
  
  .example-thumb {
    background-color: #28a745;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .example-track {
    background-color: #ddd;
    height: 10px;
  }
  
  /* Products container styling */
  .products-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  
  /* Shopping card styling */
  .shopping-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
  }
  
  .product-image {
    width: 100%;
    height: auto;
  }
  
  .product-info {
    text-align: center;
  }
  
  .product-title {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .product-description {
    font-size: 0.9em;
    color: #666;
  }
  
  .product-price {
    font-size: 1.1em;
    color: #333;
    margin: 10px 0;
  }
  
  .add-to-cart-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-btn:hover {
    background-color: #218838;
  }
  
  /* Desktop styles */
  @media (min-width: 768px) {
    .filter-container {
      width: 20%;
    }
  
    .products-container {
      width: 80%;
    }
  
    .shopping-card {
      width: calc(25% - 20px);
    }
  }
  
  /* Mobile styles */
  @media (max-width: 767px) {
    .products-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .shopping-card {
      width: calc(100% - 20px); /* Full width with small padding */
      margin: 10px auto; /* Small margin to avoid touching the screen edges */
    }
  }
  