.container {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .fileList {
    list-style-type: none;
    padding: 0;
  }
  
  .fileItem {
    margin-bottom: 10px;
    display: flex;
    align-items: center; /* Center-align items vertically */
  }
  
  .downloadButton {
    margin-right: 10px; /* Add some space between the button and the text */
    padding: 5px 10px;
    background-color: #008cba;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .downloadButton:hover {
    background-color: #007bb5;
  }
  