.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .inputBox {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 5px;
  }
  
  .keyPressed {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #007BFF;
  }
  