.cart-container {
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
  }
  
  .cart-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .cart-table th, .cart-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .cart-table th {
    background-color: #f4f4f4;
  }
  
  .cart-item {
    background-color: #fff;
  }
  
  .cart-item:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .delete-button {
    background: none;
    border: none;
    color: #d9534f;
    cursor: pointer;
  }
  
  .delete-button:hover {
    color: #c9302c;
  }
  
  .checkout-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .checkout-button:hover {
    background-color: #218838;
  }
  