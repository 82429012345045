body {
    font-family: Arial, sans-serif;
  }
  
  section {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .message {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    text-align: center;
    display: none;
  }
  
  .message.show {
    display: block;
  }
  