.api-page {
    padding: 20px;
  }
  
  .example-json {
    margin-top: 20px;
  }
  
  .example-json pre {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  .instructions {
    margin-top: 20px;
  }
  
  .instructions h2 {
    margin-bottom: 10px;
  }
  
  .instructions ol {
    margin-left: 20px;
  }
  
  .instructions li {
    margin-bottom: 10px;
  }
  