.checkbox-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  input[type="checkbox"] {
    display: none; /* Hide the default checkbox */
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
    padding: 10px;
    border: 2px solid #333;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .checkbox-label.checked {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  .icon {
    font-size: 24px;
    margin-right: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .checkbox-label.checked .icon {
    opacity: 1;
  }
  
  .checkbox-container.angry .checkbox-label.checked {
    background-color: #ff4c4c;
    border-color: #ff4c4c;
  }
  
  .checkbox-container.angry .checkbox-label .icon {
    font-size: 24px;
    margin-right: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .checkbox-container.angry .checkbox-label.checked .icon {
    opacity: 1;
  }
  