.container {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.hoverText {
  font-size: 24px;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.hiddenButton {
  opacity: 0;
  visibility: hidden;
  transform: translateX(50px);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.hoverText:hover + .hiddenButton {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.hiddenButton[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.successMessage {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
  border-radius: 5px;
  text-align: center;
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
