.container {
    font-family: Arial, sans-serif;
    padding: 20px;
    text-align: center;
  }
  
  .slider {
    width: 80%;
    margin: 20px 0;
  }
  
  .value {
    font-size: 24px;
    margin-top: 10px;
  }
  