.table-container {
    margin: 20px;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  input {
    width: 100%;
    border: none;
    padding: 8px;
    box-sizing: border-box;
  }
  