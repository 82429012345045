body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  header {
    background-color: #333;
    color: white;
    padding: 10px 0;
    text-align: center;
  }
  
  header nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  header nav ul li {
    display: inline;
    margin: 0 15px;
  }
  
  header nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }
  
  header nav ul li a:hover {
    text-decoration: underline;
  }
  
  main {
    padding: 20px;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  section h2 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
  }
  
  textarea {
    display: block;
    margin-top: 10px;
  }
  
  .drag-container {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
  
  .draggable {
    background-color: #ddd;
    padding: 10px;
    cursor: grab;
  }
  
  .dropzone {
    background-color: #f4f4f4;
    border: 2px dashed #000;
    padding: 10px;
    width: 100px;
    text-align: center;
  }
  
  .dragging {
    opacity: 0.5;
  }
  
  .drag-over {
    border: 2px solid #000;
  }
  
  .hidden {
    display: none;
  }
  
  .search-filter {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .search-filter input,
  .search-filter select {
    padding: 10px;
    font-size: 16px;
  }
  
  .products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .shopping-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: grab;
  }
  
  .shopping-card img {
    width: 100%;
    height: auto;
  }
  
  .product-info {
    text-align: center;
  }
  
  .product-title {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .product-description {
    font-size: 0.9em;
    color: #666;
  }
  
  .product-price {
    font-size: 1.1em;
    color: #333;
    margin: 10px 0;
  }
  
  .add-to-cart-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-btn:hover {
    background-color: #218838;
  }
  