.alertContainer {
    border: 2px solid #007BFF; /* Blue border */
    border-radius: 5px;
    padding: 20px;
    background-color: #E7F3FF; /* Light blue background */
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
  }
  
  .alertButton {
    background-color: #007BFF; /* Blue background */
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .alertButton:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  